<template>
  <div class="page-container">
    <div class="header" style="padding: 0;">
      <page-header @navBack="$router.go(-1)" pageTitle="理论培训电子合同"></page-header>
    </div>
    <div class="main">
      <p><strong>【重要提示】</strong>训美科技理论培训服务协议（以下简称“本协议”）是北京训美科技有限公司（以下简称“训美科技”或 “乙方”）与用户（以下简称“您”或“甲方”或“用户”）就训美科技理论培训服务使用等相关事项所订立的有效合约。<strong>在您确认确认本协议之前，请您务必仔细阅读本协议所有条款，特别是以粗体标注的条款。</strong>您需要理解并同意以下条款所述事项才能享受训美科技相关产品服务。<strong>无论您事实上是否认真阅读， 只要您勾选同意或者点击确认本协议，或者实际接受了训美科技了训美科技的服务，就表示您已经接受了本协议 ，并愿意受其约束，本协议自用户点击确认且付款成功后成立，自用户购买的产品服务开通之日起生效。</strong>如果发生纠纷，您不能以未仔细阅读为由进行抗辩。除应遵守本协议约定外，<strong>还应遵守训美科技线上《训美科技用户注册协议》、《训美科技隐私政策》</strong>等电子协议及训美科技以站内公告、站内信、手机短信、 用户后台或甲方填写的其他联系方式向甲方通知/送达的乙方相关规则、政策、通知（合称“乙方平台规则”）等信息。</p>
      <h1 class="title">训美科技理论培训服务协议</h1>
      <p>根据《中华人民共和国民法典》《中华人民共和国道路交通安全法》《中华人民共和国道路运输条例》《机动车驾驶员培训管理规定》等相关法律法规和政府管理部门规范机动车驾驶培训行业经营服务行为的管理规定，甲乙双方在自愿、平等的基础上，就机动车驾驶培训服务事宜协商一致，签署本协议。</p>
      <p><strong>第一条 服务内容</strong></p>
      <p>根据《机动车驾驶培训教学与考试大纲》（以下简称《大纲》），乙方提供的培训服务采用学时制（每学时为60分钟，其中，有效教学时间不低于45分钟），培训学时不得低于《大纲》规定的基本学时要求，内容包括“道路交通安全法律、法规和相关知识”理论知识培训、“安全文明驾驶常识”理论知识培训。</p>
      <p><strong>第二条 收费方式和收费、退费标准</strong></p>
      <p>（1）收费方式：根据乙方报名驾校与甲方的合作形式，采用学员支付或驾校支付的方式：</p>
      <p>①学员支付：学员可通过ios内购、微信、支付宝等支付方式，向甲方交纳费用。</p>
      <p>②驾校支付：学员将培训费用交纳给驾校，由驾校跟据驾校与甲方另行签署的协议向甲方或第三方付费，乙方不需要再次付费。</p>
      <p>（2）收费标准：根据驾校公示的理论培训费用为准。</p>
      <p>（3）学员支付方式下的退费标准：跟据学员申请退费时的以下三种不同情形予以退费：</p>
      <p>①在培训前提出退学的，退还全部学费；</p>
      <p>①在培训前提出退学的，退还全部学费；</p>
      <p>①在培训前提出退学的，退还全部学费；</p>
      <p>退还的学费原路退回至学员支付账号。</p>
      <p><strong>第三条 用户承诺</strong></p>
      <p>为贯彻落实交通运输部、全国关于机动车驾驶员的培训要求，同时基于网络服务的特殊性，使用北京训美科技有限公司的培训用户应作如下承诺：</p>
      <p><strong>（1）自愿选择“北京训美科技有限公司”进行相关课程的网络远程培训，并遵守国家、地方法律、法规、政策和“北京训美科技有限公司”关于网络远程培训的有关规定。如有违背以上内容之情形，本自愿承担一切后果及责任。</strong></p>
      <p><strong>（2）凡是以本人身份证号登录的账号操作视为本人所为。如发现有作弊或代学行为，则本人学时作废，并放弃退还学费的要求；一旦发生道路交通安全责任事故，本人将承担相应的法律责任。</strong></p>
      <p><strong>（3）学习课程开通后，在有效时间（以训美科技平台显示的为准）内不能完成全部课程学习的，视为自动放弃本次培训。</strong></p>
      <p><strong>（4）学员应该严格执行甲方制订的远程教育使用相关规定，学员不得要求甲方调整及帮助学员进行任何形式的学时作假。</strong></p>
      <p><strong>第四条 条款修改</strong></p>
      <p><strong>训美科技有权在必要时修改服务条款，服务条款一旦发生变动，训美科技将会在重要页面上提示修改内容。如继续使用训美科技提供的服务，则视为接受服务条款的变动。</strong></p>
      <p><strong>第五条 账号安全</strong></p>
      <p>用户成功登录网站或app，并注册成为训美科技的合法学员后，应妥善保管用户名、密码；用户名若因遗失、泄密等原因被他人使用，或自行提供给他人使用，均视为学员本人使用，<strong>并对由此产生的一切后果承担全部法律责任。</strong></p>
      <p>为了充分保护您的账户安全，当您访问训美科技及其相关网站提供的服务时，系统会记录您操作的相关信息，这些信息可帮助我们更好地识别您的身份以及保护您的账户安全，例如您的账户在可疑操作环境下登录的，系统可能监控到您的账户风险而采取一些措施。同时，为了向您提供更全面的安全服务，您同意训美科技将前述相关信息提供给训美科技及其关联公司。</p>
      <p><strong>第六条 学员管理</strong></p>
      <p>严禁发表、散布、传播任何反动、色情及违反国家安全、扰乱社会秩序等有害信息，学员需对自己在网上的行为承担法律责任。若出现在网上散布和传播反动、色情或其他违反国家法律的信息之情形，训美科技的系统记录将作为其违法行为的证据。</p>
      <p><strong>您在使用本软件的过程中，违反本协议规定的训美科技有权采取包括但不限于停止提供服务、限制使用、法律追究等措施。</strong></p>
      <p><strong>第七条 所有权声明</strong></p>
      <p>训美科技定义的网络服务内容，包括各类网络课件、练习题、考题等全部内容，训美科技拥有以上内容的完全版权。严禁任何个人或单位在未经训美科技许可的情况下对上述内容进行翻版、复制、转载、篡改等一切的行为。</p>
      <p><strong>训美科技的学员账号只为本人所专有，严禁一个账号多人使用，如若发现上述情况，训美科技将有权停止其账号使用，并保留追究其法律责任的权利。</strong></p>
      <p><strong>第八条 免责条款</strong></p>
      <p>本公司培训课件及服务功能虽经过详细的测试，但不能保证与所有的软硬件系统完全兼容，不能保证完全没有错误。如果出现不兼容及错误的情况，您可拨打客服电话获得技术支持。</p>
      <p><strong>训美科技以链接形式推荐其他网站，并不对这些网站或资源的可用性负责，且不保证从这些网站获取的任何内容、产品、服务或其他材料的真实性、合法性，对于任何因使用或信赖从此类网站或资源上获取的内容、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，训美科技均不承担任何责任。</strong></p>
      <p>由于学员将个人密码告知他人而导致的任何个人资料泄露，本公司不承担任何责任。</p>
      <p>网站受不可抗力因素影响造成的个人资料泄露、丢失、被盗用、被篡改或不能正常学习、网站暂时性关闭等，本公司均得免责。不可抗力主要包括以下几种情形：</p>
      <p>1.自然灾害，如台风、洪水、地震等；</p>
      <p>2.政府行为，如征收、征用等；</p>
      <p>3.社会异常事件，如罢工、骚乱等。</p>
      <p>本公司如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本公司控制范围内的硬件故障或其它不可抗力因素而导致暂停服务，本公司将于恢复后立即公告具体原因。</p>
      <p>用户因为违反本声明的规定或触犯中华人民共和国法律的，一切后果自负，本公司不承担任何责任。</p>
      <p><strong>第九条 争议解决及其他条款</strong></p>
      <p>您同意，本协议适用中华人民共和国法律。因北京训美科技有限公司与您就本协议的签订、履行或解释发生争议，双方应努力友好协商解决。<strong>如协商不成，甲乙双方同意由合同签订地北京市西城区人民法院管辖审理双方的纠纷或争议。</strong></p>
      <p>如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除，但本协议的其余条款仍应有效并且有约束力。</p>
      <p><strong>本协议未约定事宜，均以训美科技所不时公布的通知、公告及相关附属规则为补充。</strong></p>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
export default {
  name: "contract",
  components: {
    PageHeader,
  }
}
</script>

<style scoped>
  .main {
    padding: 0 15px;
  }
  .main p {
    font-size: 14px;
    text-indent: 28px;
    line-height: 20px;
    text-align: justify;
  }
  .main h1.title {
    text-align: center;
    font-size: 18px;
    margin: 30px 0;
  }
</style>
